body {
 width: 100%;
 height: 100%;
 margin: auto;
 position: fixed;
 top:0;
 right: 0;
 bottom: 0;
 left: 0;
 overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

heart {
  position: absolute;
  width: 20px;
  height: 18px;
  margin-top: -9px;
  margin-left: -10px;
}

heart:before,
heart:after {
  position: absolute;
  content: "";
  left: 10px;
  top: 0;
  width: 10px;
  height: 16px;
  background: #f88dc8;
  border-radius: 10px 10px 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin :100% 100%;
}

heart.blue:before, heart.blue:after {
  background: #AFDFE4;
}

heart.green:before, heart.green:after {
  background: #3cb371;
}
